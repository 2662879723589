<template>
  <Layout class="module-edit">
    <div class="content">
      <h4 class="title">
        模块信息
      </h4>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="80px"
        label-position="left"
      >
        <el-form-item
          label="Key"
          prop="key"
        >
          <el-input
            v-model="formData.key"
            clearable
            placeholder="唯一key"
          />
        </el-form-item>
        <el-form-item
          label="模块名称"
          prop="title"
        >
          <el-input
            v-model="formData.title"
            clearable
            placeholder="名称将展示在APP"
          />
        </el-form-item>
        <!--        <el-form-item label="排序">-->
        <!--          <el-input-->
        <!--            v-model="formData.weight"-->
        <!--            clearable-->
        <!--            placeholder="单行输入"-->
        <!--          />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item-->
        <!--          label="位置"-->
        <!--          prop="position"-->
        <!--        >-->
        <!--          <el-select-->
        <!--            v-model="formData.position"-->
        <!--            clearable-->
        <!--          >-->
        <!--            <el-option-->
        <!--              v-for="(item, index) in appModulePositionList"-->
        <!--              :key="index"-->
        <!--              :label="item.name"-->
        <!--              :value="item.value"-->
        <!--            />-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item
          label="描述"
          prop="desc"
        >
          <el-input
            v-model="formData.desc"
            type="textarea"
            placeholder="多行输入"
            maxlength="500"
            :autosize="{ minRows: 4 }"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            active-value="1"
            inactive-value="2"
            active-text="上架"
            inactive-text="下架"
          />
        </el-form-item>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="loading"
          type="primary"
          @click="createGroup"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import { appModulePositionList } from '@/utils/selectOptions'
import { createOrSaveGroup, queryGroupDetail } from '@/api/APP'

export default {
  name: 'ModuleEdit',
  data () {
    return {
      appModulePositionList,
      loading: false,
      formData: {
        title: '',
        // position: '1',
        desc: '',
        status: '1'
      },
      isCreated: true,
      rules: {
        key: [{ required: true }],
        title: [{ required: true }],
        // position: [{ required: true }],
        desc: [{ required: true }],
        status: [{ required: true }]
      }
    }
  },
  created () {
    const key = this.$route.query.key
    this.isCreated = !key
    if (key) {
      queryGroupDetail({ key })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data.oneData
          }
        })
    }
  },
  methods: {
    handleCancel () {
      this.$router.go(-1)
    },
    createGroup () {
      this.$refs.form.validate()
        .then(valid => {
          if (valid) {
            this.loading = true
            createOrSaveGroup(this.formData)
              .then(res => {
                if (res.code === 200) {
                  const message = this.isCreated ? '新建成功' : '更新成功'
                  this.$notify({
                    title: '成功',
                    message: message,
                    type: 'success'
                  })
                  this.$router.go(-1)
                }
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
    }
  }
}
</script>
<style scoped lang="less">
  .module-edit{
    .content{
      width: 500px;
      margin: 50px 80px;
    }
  }
  h4.title{
    margin-bottom: 50px;
  }
.ctrl-area{
  margin-top: 80px;
  .el-button{
    padding:10px  40px;
  }
}
</style>
